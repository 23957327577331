<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title>Sinterklaasshow</b-card-title>
                <b-card-text>
                    <b-img align="right" class="w-50 cardimage"  v-bind:src="$imagestore1+'/website%202018/Ridder-en-de-Prins---Kleine-show.jpg'" fluid></b-img>
                    <p>Tijdens onze show is afwisseling hét toverwoord. De Pieten nemen de kinderen mee in hun avontuur. Zang, doe of dans en dit alles op verrassende liedjes. Sinterklaas wisselt soepel de Pieten af met aandacht voor het individuele kind. Sint
                        weet over iedereen wel iets leuks en wie dat wil mag natuurlijk op schoot of een handje geven.</p>
                    <p> Die afwisseling maakt dat de kinderen goed met de spanning van ons bezoek kunnen omgaan en maakt het extra gezellig. De volwassenen? Die delen mee in het plezier. ‘Wie heeft er een sterke mama?’ Voor de kinderen en de ouders is het daarmee
                        één groot familiefeest.</p>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col md="12">
            <b-card>
                <b-card-title> Grote boek van Sint</b-card-title>
                <b-card-text>
                    <b-img align="left" class="w-50 cardimage"  v-bind:src="$imagestore1+'/website%202018/Ridder-en-de-Prins---Sint-met-Grote-boek.jpg'" fluid alt="Fluid image"></b-img>
                    Voor uw show maken wij een unieke webpagina aan waar ouders zich aanmelden en de informatie over hun kinderen kunnen invullen. Het Grote Boek is daardoor altijd netjes actueel en zijn er geen verschillende lijsten met namen, leeftijden of cadeaus. U, als organisator, kunt ook in de database en zo heeft iedereen de juist informatie.
                </b-card-text>
            </b-card>
        </b-col>
        <b-col md="12">
            <b-card>
                <b-card-title> Repertoire van Piet</b-card-title>
                <b-card-text>
                    <p>Sinds dag één zijn we bezig met het ontwikkelen van ons eigen repertoire. Rustige en stille verhalen tot energieke spellen over paarden, pepernoten of stoomboten. Ook maken we liedjes over tandenpoetsen, buiten spelen of knutselen, vaak
                        begeleid met een videoclip ter voorpret. Onze liedjes gaan over mooie kinderthema’s die we goed samen met de kinderen kunnen uitbeelden. Zo is Piet er voor de groep en focust Sint zich op het individu.</p>
                    <p> Enkele van onze eigen nummers zijn:</p> 
                       <silent-box class="galleryitems" :gallery="repertoire"></silent-box>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col md="12">
            <b-card>
                <b-card-title>Sfeer, muziek en ondersteuning</b-card-title>
                <b-card-text>
                    <b-img align="left" class="w-50 cardimage"  v-bind:src="$imagestore1+'/website%202018/Ridder-en-de-Prins---Techniek.jpg'" fluid alt="Fluid image"></b-img>
                    De spanning stijgt, zien we al de staf of een veer? Onze techniek heeft de sfeermuziek al aangezet en stemt de laatste wijzigingen af tussen Piet in de auto en de organisatie. Bij aankomst krijgen Piet en Sint een microfoon in handen en kunnen zo doorlopen
                    naar de zaal. Afhankelijk van wat de acteurs nodig hebben zetten de technici zich in. Zo is onze techniek niet alleen voor de sfeer en de muziek maar ook echt als ondersteuning van het gehele feest.
                </b-card-text>
            </b-card>
        </b-col>
        <b-col md="12">
            <b-card>
                <b-card-title>Cadeauservice</b-card-title>
                <b-card-text>
                    <b-img align="right" class="w-50 cardimage"  v-bind:src="$imagestore1+'/website%202018/Ridder-en-de-Prins---Cadeauservice.jpg'" fluid alt="Fluid image"></b-img>
                    Eind november geven wij heel wat gretige kinderhanden een cadeau. Aan het eind van de show komen die tevoorschijn en daarna verlaten we met gezang de zaal. Onze cadeauservice ontzorgt u volledig in dit proces. De ouders vullen digitaal het Grote Boek in en kiezen gelijk per kind een cadeau. Van te voren kiest u een prijscategorie, €15,- of €20,- Wij maken een voorselectie van 24 tot 30 degelijke en hoogwaardige cadeaus waarbij er zeker voor iedereen iets leuks te kiezen is. Ook nemen wij reserve cadeaus mee voor als er onverwachts nog een broertje of zusje meekomt. Zo staat niemand met lege handen.                </b-card-text>
            </b-card>
        </b-col>
        <b-col md="12">
            <b-card>
                <b-card-title>Reserveer direct</b-card-title>
                <b-card-text>
                    <b-link to="/contact">
                        <b-img align="right" class="w-50 cardimage"  v-bind:src="$imagestore1+'/website%202018/Ridder-en-de-Prins---Piet-schrijft-in-het-Grote-boek.jpg'" fluid alt="Fluid image"></b-img>
                    </b-link>
                    <p>
                    Laat ons weten wat u zoekt en leg een prachtige Sinterklaasshow vast.
                    </p>
                    <b-link to="/contact">
                        <b-img align="left" v-bind:src="$imagestore1+'/logo/boeknu.png'" fluid alt="Fluid image"></b-img>
                    </b-link>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Sinterklaasshow',
        data: function() {
            return {
                repertoire: [{
                        src: 'https://www.youtube.com/watch?v=nvkMp7pF-4U'
                    },
                    {
                        src: 'https://www.youtube.com/watch?v=7IdAfQsrcdw'
                    },
                    {
                        src: 'https://www.youtube.com/watch?v=hIuchunSDSs'
                    }
                ]
            }
        }
    }
</script>

<style>

</style>

